import { createRouter, createWebHistory } from "vue-router";

import BranchDashboard from "@/views/branch/BranchDashboard.vue";
import Branches from "@/modules/business/modules/branches/views/Branches.vue";
import BranchView from "@/views/branch/BranchView.vue";
import Businesses from "@/modules/business/views/Businesses.vue";
import BusinessView from "@/views/businesses/BusinessView.vue";
import Config from "@/views/Config.vue";
import Dashboard from "@/views/Dashboard.vue";
import HistoryView from "@/views/branch/orders/HistoryView.vue";
import KitchenView from "@/views/branch/orders/KitchenView.vue";
import Login from "@/views/Login.vue";
import Menu from "@/modules/dashboardGlobal/modules/menu/layouts/MenuLayout.vue";
import MenuView from "@/views/branch/orders/MenuView.vue";
import Onboarding from "@/views/Onboarding.vue";
import OrdersView from "@/views/branch/OrdersView.vue";
import QrTablesView from "@/views/branch/QrTablesView";
// import Register from "@/views/Register.vue";
import Users from "@/views/dashboard/Users.vue";
// import Home from "@/modules/dashboardGlobal/modules/home/views/Home.vue";
import branchRouter from "@/modules/branch/router";
import liteBranchRouter from "@/modules/lite/router";
import liteDashboard from "@/modules/liteDashboard/router";

// import RegisterPage from "../modules/loginAndRegister/pages/RegisterPage"

const routes = [
  {
    path: "",
    name: "Login",
    component: Login,
  },
  {
    // path: "/register",
    // name: "Register",
    // component: Register
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Onboarding,
  },
  {
    path: "/config",
    name: "config",
    component: Config,
  },

  //Login and Register
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "RegisterPage" */ "../modules/register/pages/RegisterPage.vue"
      ),
    // component: RegisterPage
  },

  //CLIENT
  {
    path: "/dashboard/:business",
    name: "dashboard",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "branches",
        component: Branches,
      },
      {
        path: "menu",
        name: "menu",
        component: Menu,
      },
      {
        path: "shop-details",
        name: "BusinessView",
        component: BusinessView,
      },
      {
        path: "users",
        name: "users",
        component: Users,
      },
    ],
  },
  {
    path: "/:business/branch-old/:branchId",
    name: "branch-old",
    component: BranchDashboard,
    children: [
      {
        path: "",
        name: "branch-view",
        component: BranchView,
      },
      {
        path: "/:business/orders/:branchId",
        name: "orders",
        component: OrdersView,
      },
      {
        path: "/:business/kitchen/:branchId",
        name: "kitchen",
        component: KitchenView,
      },
      {
        path: "/:business/history/:branchId",
        name: "history",
        component: HistoryView,
      },
      {
        path: "/:business/menu/:branchId",
        name: "menu-view",
        component: MenuView,
      },
      {
        path: "/:business/qr-tables/:branchId",
        name: "qr-tables",
        component: QrTablesView,
      },
    ],
  },
  {
    path: "/businesses",
    name: "businesses",
    component: Businesses,
  },
  // BRANCH_NEW
  {
    path: "/:business/branch/:branchId",
    ...branchRouter,
  },
  {
    path: "/lite",
    name: "lite",
    ...liteBranchRouter,
  },
  {
    path: "/lite-dashboard",
    ...liteDashboard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
